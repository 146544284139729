<template>
  <div class="viewFile">
    <el-table :data="fileList">
      <el-table-column label="文件名"
                       align="center"
                       prop="fileName"></el-table-column>
      <el-table-column label="发送人"
                       align="center"
                       prop="opername"></el-table-column>
      <el-table-column label="操作"
                       align="center">
        <template slot-scope="scope">
          <el-button type="primary"
                     :disabled="!scope.row.fileUrl"
                     @click="loadFile(scope.row.fileUrl)">下载</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "viewFile",
  data() {
    return {
      id: this.$route.query.id,
      fileList: [],
      managerList: [],
    };
  },
  created() {
    this.loadAllFile();
  },
  methods: {
    loadAllFile() {
      this.$post("/base/queryDocumentPerson.do", { did: this.id })
        .then((res) => {
          res.data.fileList.forEach((file) => {
            if (!file.fileUrl) {
              file.fileName = "";
            }
          });
          this.fileList = res.data.fileList;
          this.managerList = res.data.managerList;
        })
        .catch((err) => {
          this.$message({ type: "warning", message: err.message });
        });
    },

    loadFile(url) {
      let urlList = url.split(",");
      if (urlList.length === 1) {
        window.open(this.$fileUploadDomain + url);
      } else {
        this.downZip(urlList);
      }
    },
    // 批量下载
    downZip(urlList) {
      const that = this;
      this.$postFile("/base/ZipOss.do", urlList)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/zip",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
